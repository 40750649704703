import React from 'react';
import { graphql } from 'gatsby';
import { Layout, PostHeader, PostBodyHead, PostFooter, PostsGrid, BlockContent, Section } from 'components';
import logo from 'images/logo.png';
import * as styles from './post.module.scss';

const Post = (props) => {
  const { location } = props;
  const slug = location.pathname;
  const { sanityPost } = props.data;
  const {
    title,
    subtitle,
    mainImage,
    postAuthorRef,
    publishedAt,
    _rawBody,
    categories,
    seo: seoObject,
    _updatedAt,
  } = sanityPost;

  const { authorName, authorPhoto, authorBio } = postAuthorRef || {};

  const headerData = {
    title,
    subtitle,
    slug,
    mainImage,
  };

  const author = {
    name: authorName || 'Moody Kiddell & Partners',
    photo: authorPhoto || '',
    bio: authorBio || '',
  };

  const bodyData = {
    author,
    publishedAt,
    body: _rawBody,
    slug,
  };
  const footerData = {
    author,
    categories,
    slug,
  };

  const postsData = {
    title: 'Our Related Articles',
    tileBG: true,
    subtitle:
      'Problems trying to resolve the conflict between the two major realmsof Classical physics: Newtonian mechanics',
  };

  const seo = {
    title: seoObject?.seo_title || '',
    desc: seoObject?.meta_description || '',
    url: `https://mkpgroup.com.au${slug}`,
    node: {
      first_publication_date: publishedAt,
      last_publication_date: _updatedAt,
    },
  };

  return (
    <Layout location={location} seo={seo} article>
      <PostHeader data={headerData} location={location} categories={categories} />
      <PostBodyHead data={bodyData} />
      <Section className={styles.body}>
        {_rawBody && (
          <BlockContent data={_rawBody} publishedAt={publishedAt} location={location} className="block-content" />
        )}
      </Section>
      <PostFooter data={footerData} />
      <PostsGrid data={postsData} />
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query Post($id: String!) {
    sanityPost(id: { eq: $id }) {
      slug {
        current
      }
      _createdAt
      _updatedAt(formatString: "MMM DD, YYYY")
      id
      title
      subtitle
      seoKeywords
      seo {
        meta_description
        seo_title
      }
      publishedAt(formatString: "MMM DD, YYYY")
      _rawBody(resolveReferences: { maxDepth: 8 })
      categories {
        title
        slug {
          current
        }
      }
      mainImage {
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 1500, placeholder: BLURRED)
            url
          }
        }
      }
      postAuthorRef {
        authorName
        authorBio
        authorPhoto {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 1500, placeholder: BLURRED)
            url
          }
        }
      }
    }
  }
`;
